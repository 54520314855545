import React from 'react'
import { Link } from 'react-router-dom'
import "./Register.css"

function Register(props) {
    return (
        <section id='auth'>
             <div className='container'>
             <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-right">
                            <h1>Welcome Back!</h1>
                            <p>Please Login with your personal info</p>
                            <Link to={`/login`} className="btn-login ghost">Sign In</Link>
                        </div>
                    </div>
                </div>
                
                <div className="form-container sign-in-container">
                    <form autoComplete="off">
                     <Link to={`/`}>
                        <img src="https://res.cloudinary.com/dfshihuf9/image/upload/v1695474469/Images/logo-hign-res.svg" alt="no image" className="logo-img" />
                     </Link>
                        <hr />
                        <h1>Sign Up</h1>
                        {/* <div className="social-container">
                            <Link to={`#`} className='social'> <i className="fab fa-instagram"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-git"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-linkedin"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-facebook"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-google"></i> </Link>
                        </div> */}
                        <span>or use your email for registration</span>
                        <input type="text" name="name" id="name" placeholder="Full name" required />
                        <input type="number" name="mobile" id="mobile" placeholder="mobile number" required />
                        <input type="email" name="email" id="email" placeholder='username' required />
                        <input type="password" name="pass" id="pass" placeholder='password' required />
                            
                            <button className='btn-login'>Sign Up</button>
                    </form>
                </div>

                
            </div>
       </section>
    )
}

export default Register