import React from 'react'

function Policy() {
  return (
    <div>
        <h1>Private Policy</h1>
    </div>
  )
}

export default Policy
