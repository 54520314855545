import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import Login from '../component/Auth/Login/Login'
import Register from '../component/Auth/Register/Register'
import Header from '../component/common/Header/Header'
import Footer from '../component/common/Footer/Footer'
import Home from '../component/Home/Home'
import About from '../component/About/About'
import Contact from '../component/Contact/Contact'
import Blog from '../component/Blog/Blog'
import Carrers from '../component/Carrers/Carrers'
import Pnf from '../component/Pnf/Pnf'
import Policy from '../component/Policy/Policy'
import Terms from '../component/Terms/Terms'

const Layout = () => (
    <>
        <Header/>
            <Outlet/>
        <Footer/>
    </>
)


const webRouter = createBrowserRouter([
    {
       path: `/`,
       element: <Layout/>,
       children: [
            {
                path: `/`,
                element: <Home/>
            },
            {
                path: `/about`,
                element: <About/>
            },
            {
                path: `/contact`,
                element: <Contact/>
            },
            {
                path: `/blogs`,
                element: <Blog/>
            },
            {
                path: `/carrers`,
                element: <Carrers/>
            },
            {
                path: `/privacy-policy`,
                element: <Policy/>
            },
            {
                path: `/terms-conditions`,
                element: <Terms/>
            },
       ]
    },
    {
      path: `/login`,
      element: <Login/>
    },
    {
        path: `/register`,
        element: <Register/>
    },
    {
        path: `/*`,
        element: <Pnf/>
    }
])


function AllRoute(props) {
    return (
        <RouterProvider router={webRouter} />
    )
}

export default AllRoute