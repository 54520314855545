import React from 'react'
import "./pnf.css"
import { Link } from 'react-router-dom'

function Pnf() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
            <h1>4<span>0</span>4</h1>
        </div>
        <h2>the page you requested could not found</h2>
            <Link to={`/`} className="btn">Back To Home</Link>
        </div>
    </div>
  )
}

export default Pnf
