import React from 'react'
import "./Home.css"

function Home(props) {
    return (
        <section id='home'>
            <div className="container">
               <main className="course-heading">
                    <div className="content-container">
                            <h4>Online Education</h4>
                            <h1>Web technologies E-Learning Academy</h1>
                            <p>Your Ultimate Learning Experience...!</p>
                     </div>
                     <div className="course-image">
                     <div className="circles">
                            <div className="circle circle-1"></div>
                            <div className="circle circle-2"></div>
                            </div>
                     </div>
               </main>
            </div>

            <div className="container">
                <div className="study-container">
                        <div className="study-row">
                            <div className="study-image">
                            </div>
                            <div className="study-content">
                                <h1>Learn Programming for free</h1>
                                <p>Learn our freshers friendly tutorials</p>
                            </div>
                        </div>
                    </div>
            </div>


            <div className="container">
                <div className="why-container">
                    <div className="why-row">
                    <div className="why-content">
                        <div className="why-title">
                            <h1>Why WebGurukula?</h1>
                        </div>
                        <div className="why-block">
                            <div className="first">
                                <h3>Simplicity</h3>
                                <p>Our materials are designed to reach freshers and having simple contents with programs to understand and coding.</p>
                            </div>
                            <div className="second">
                                <h3>Dedicated Content</h3>
                                <p>All contents and materials are prepared by group of expert developers to reach the industry standard.</p>
                            </div>
                            <div className="third">
                                <h3>Examples based</h3>
                                <p>Our tutorials are equipped with easy, simple and standard example codes developed in consider with freshers and experience.</p>
                            </div>
                        </div>
                    </div>
                        <div className="why-image"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default  Home