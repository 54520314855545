import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom'
import { tutorials, examples, interview, company } from '../../../data/footer'

function Footer() {
  return (
    <section id='footer'>
        <div className="img-container">
            <img src="logo-white.svg" alt="no image" className='logo-img' />
        </div>
        <div className="footer-container">
            <div className="about">
                <h3>About</h3>
                <p>Join our news letter for latest Updates</p>
                <div className="form">
                  <input type="email" name="email" id="email" placeholder='Enter your mail id' required />
                  <button className="btn-login">Join</button>
                </div>
            </div>

            <div className="tutorials">
                <h3>Tutorials</h3>
                <nav className='footer-menu'>
                    {
                      tutorials && tutorials.map((item,index) => {
                          return (
                            <Link key={index} to={`/tutorial/${item.title}`} className="link"> {item.title} </Link>
                          )
                       })
                    }
                </nav>
            </div>

            <div className="examples">
                <h3>Examples</h3>
                <nav className='footer-menu'>
                    {
                      examples && examples.map((item,index) => {
                          return (
                            <Link key={index} to={`/examples/${item.title}`} className="link"> {item.title} </Link>
                          )
                       })
                    }
                </nav>
            </div>

            <div className="interview">
                <h3>Interview</h3>
                <nav className='footer-menu'>
                    {
                      interview && interview.map((item,index) => {
                          return (
                            <Link key={index} to={`/interview/${item.title}`} className="link"> {item.title} </Link>
                          )
                       })
                    }
                </nav>
            </div>

            <div className="company">
                <h3>Company</h3>
                <nav className='footer-menu'>
                    {
                      company && company.map((item,index) => {
                          return (
                            <Link key={index} to={`/${item.path}`} className="link"> {item.title} </Link>
                          )
                       })
                    }
                </nav>
            </div>


        </div>
        <hr />
        <div className="footer-label">
            <p>Webgurukula pvt Ltd.All rights reserved. { new Date().getFullYear()} </p>
            <div className="social-container">
                            <Link to={`#`} className='social'> <i className="fab fa-instagram"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-git"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-linkedin"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-facebook"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-google"></i> </Link>
            </div>
        </div>
    </section>
  )
}

export default Footer
