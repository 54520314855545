import React from 'react'
import { Link } from 'react-router-dom'
import "./Login.css"

function Login(props) {
    return (
       <section id='auth'>
             <div className='container'>
                <div className="form-container sign-in-container">
                    <form autoComplete="off">
                        <Link to={`/`}>
                            <img src="https://res.cloudinary.com/dfshihuf9/image/upload/v1695474469/Images/logo-hign-res.svg" alt="no image" className="logo-img" />
                        </Link>
                        <hr />
                        <h1>Sign In</h1>
                        {/* <div className="social-container">
                            <Link to={`#`} className='social'> <i className="fab fa-instagram"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-git"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-linkedin"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-facebook"></i> </Link>
                            <Link to={`#`} className='social'> <i className="fab fa-google"></i> </Link>
                        </div> */}
                        <span>or use your account</span>
                        <input type="email" name="email" id="email" placeholder='username' required />
                        <input type="password" name="pass" id="pass" placeholder='password' required />
                            <Link to={`#`}>Forgot Your Password</Link>
                            <button className='btn-login'>Sign In</button>
                    </form>
                </div>

                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-right">
                           
                            <h1>Hello Friend!</h1>
                            <p>Enter your personal details and start your journey with us</p>
                            <Link to={`/register`} className="btn-login ghost">Sign Up</Link>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    )
}

export default Login