import React from 'react'
import { Link } from 'react-router-dom'
import "./Menu.css"
import { tutorials, examples, interview } from '../../../data/footer'
const logoImge = 'https://res.cloudinary.com/dfshihuf9/image/upload/v1695474469/Images/logo-hign-res.svg'

function Menu() {

    const display = () =>{
        let menu = document.querySelector(".menu-right")
        menu.classList.toggle('active');
    }

    const toggle = (val) => {
        
        let dropMenu = document.querySelector(`#${val}`);
           if(dropMenu.id === "tutorials") {
            dropMenu.classList.add("active");
           } else if (dropMenu.id === "examples") {
            dropMenu.classList.add("active");
           } else if (dropMenu.id === "interview") {
            dropMenu.classList.add("active");
           }
        // console.log('id =', dropMenu.id)
    }
    

    const remove = (val) => {
        let dropMenu = document.querySelector(`#${val}`);
        setTimeout(() => {
            dropMenu.classList.remove('active');
       }, (3000));
    }

  return (
    <nav className="menu-container">
        <div className="logo-img">
            <Link to={`/`} className="logo-link">
                <img src={logoImge} alt="no image" />
            </Link>
        </div>

        <button className='nav-toggler' onClick={display}>
            <div></div>
            <div></div>
            <div></div>
        </button>

        <div className="menu-right">
            <ul className="nav-menu">
              <li className="nav-item">
                  <Link to={`/#`} onMouseDown={() => toggle('tutorials')}  onMouseUp={()=> remove('tutorials')} className="nav-link">Tutorials</Link>
                  <ul id='tutorials' className="dropdown-menu">
                        {
                            tutorials && tutorials.map((item,index) => {
                                return (
                                    <li key={index}><Link to={`/tutorial/${item.title}`}>{item.title}</Link></li>
                                )
                            })
                        }
                        </ul>
              </li>
              <li className="nav-item">
                  <Link onMouseDown={() => toggle('examples')} onMouseUp={()=> remove('examples')} to={`/#`} className="nav-link">Examples</Link>
                  <ul id='examples' className="dropdown-menu">
                        {
                            examples && examples.map((item,index) => {
                                return (
                                    <li key={index}><Link to={`/examples/${item.title}`}>{item.title}</Link></li>
                                )
                            })
                        }
                 </ul>
              </li>
              <li onMouseDown={() => toggle('interview')} onMouseUp={()=> remove('interview')} className="nav-item">
                  <Link to={`/#`} className="nav-link">Interview</Link>
                  <ul id='interview' className="dropdown-menu">
                        {
                            interview && interview.map((item,index) => {
                                return (
                                    <li key={index}><Link to={`/interview/${item.title}`}>{item.title}</Link></li>
                                )
                            })
                        }
                 </ul>
              </li>
            </ul>

            <ul className="profile">
                <li className="nav-item">
                    <Link to={`/login`} className="nav-link">Login</Link>
                </li>
                <li className="nav-item">
                    <Link to={`/register`} className="nav-link">Register</Link>
                </li>
            </ul>
        </div>
    </nav>
  )
}

export default Menu
