import React from 'react'
import "./Header.css"
import { Link } from 'react-router-dom'
import Menu from '../Menu/Menu'

function Header() {
  return (
    <section id='header'>
          <div className="offer">
              <p>Get Certified Today.. Hurry Up!.. Limited time offer</p>
              <p>
                <strong>Call Us: </strong>
                <strong className="text-call">
                    <Link className='link' to={`tel:+91 8088558746`}>+91 8088558746</Link>
                </strong>
              </p>
          </div>
          <div className="menu-block">
            <Menu/>
          </div>
    </section>
  )
}

export default Header
