
const examples = [
    {
        id: 1,
        title: "HTML"
    },
    {
        id: 2,
        title: "CSS"
    },
    {
        id: 3,
        title: "JavaScript"
    },
    {
        id: 4,
        title: "React"
    },
    {
        id: 5,
        title: "NodeJS"
    },
    {
        id: 6,
        title: "ExpressJS"
    },
    {
        id: 7,
        title: "REST-API"
    },
];

const tutorials = [
    {
        id: 1,
        title: "HTML"
    },
    {
        id: 2,
        title: "HTML-5"
    },
    {
        id: 3,
        title: "CSS"
    },
    {
        id: 4,
        title: "CSS-3"
    },
    {
        id: 5,
        title: "JavaScript"
    },
    {
        id: 6,
        title: "React"
    },
    {
        id: 7,
        title: "NodeJS"
    },
    {
        id: 8,
        title: "ExpressJS"
    },
    {
        id: 9,
        title: "MongoDB"
    },
    {
        id: 10,
        title: "REST-API"
    },
];


const interview = [
    {
        id: 1,
        title: "Agile"
    },
    {
        id: 2,
        title: "CSS & CSS-3"
    },
    {
        id: 3,
        title: "Code-Examples"
    },
    {
        id: 4,
        title: "Data-Structures"
    },
    {
        id: 5,
        title: "Design Patterns"
    },
    {
        id: 6,
        title: "Git" 
    },
    {
        id: 7,
        title:"HTML & HTML-5"
    },
    {
        id: 8,
        title: "JavaScript"
    },
    {
        id: 9,
        title: "React"
    },
    {
        id: 10,
        title: "REST API"
    }
];


const company = [
    {
        id: 1,
        title: "About",
        path: "about"
    },
    {
        id: 2,
        title: "Blogs",
        path: "blogs"
    },
    {
        id: 3,
        title: "Carrers",
        path: "carrers"
    },
    {
        id: 4,
        title: "Contact",
        path: "contact"
    },
    {
        id: 5,
        title: "Privacy Policy",
        path: "privacy-policy"
    },
    {
        id: 6,
        title: "Terms & Conditions",
        path: "terms-conditions"
    }
]


export { tutorials, examples, interview, company }