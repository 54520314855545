import React from 'react'
import AllRoute from "./AllRoute/AllRoute";

function App(props) {
  return (
    <React.Fragment>
            <AllRoute/>
    </React.Fragment>
  )
}

export default App
