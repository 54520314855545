import React from 'react'
import "./blog.css"

function Blog() {
  return (
    <div>
        <h1>Blog</h1>
    </div>
  )
}

export default Blog
