import React from 'react'
import "./Contact.css"

function Contact(props) {
    return(
        <div>
            <h1>Contact</h1>
        </div>
    )
}

export default Contact